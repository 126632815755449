import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";

interface ThumbnailsSprite {
  url: string; // thumbnail sprite's url
  start: number; // start timestamp of this sprite in video
  duration: number; // duration of this sprite in video
  width: number; // width of each preview image
  height: number; // height of each preview image
  interval: number; // interval of each preview images in sprite

  // Other
  cells: number;
  rows: number;
}

export function getThumbnailFromUrl(thumbnailUrl: string): null | ThumbnailsSprite {
  const [url, config] = thumbnailUrl.split("#");

  if (!url || !config) {
    return null;
  }

  const { d: interval, wh, grid } = parseObjectFromString(config);
  const [width, height] = wh.split(",");
  const [cells, rows] = grid.split(",");

  return {
    start: 0,
    url,
    width,
    height,
    duration: cells * rows * interval,
    interval,
    // #
    cells,
    rows,
  };
}

export function updateThumbnailsInPlayer(player: IPixellotPlayer, thumb?: ThumbnailsSprite | null): void {
  const options = {
    url: thumb?.url,
    width: thumb?.width,
    height: thumb?.height,
    interval: Number(thumb?.interval),
    duration: Number(thumb?.duration),
    rows: Number(thumb?.rows),
    cells: Number(thumb?.cells),
  };

  player.spriteThumbnails().updateOptions(options);
}

function parseObjectFromString(str: string): Record<string, any> {
  const pattern = new RegExp("([^?=&]+)(=([^&]*))?", "g");
  const matches = str.match(pattern);

  if (!matches) {
    return {};
  }

  return matches.reduce(
    (acc, item) => {
      const [key, value] = item.split("=");

      acc[key] = value;

      return acc;
    },
    {} as Record<string, any>,
  );
}
