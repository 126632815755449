<script setup lang="ts">
import type { IBasicVideo } from "@/types";

const props = defineProps<{
  video: IBasicVideo;
  categoryName?: string;
}>();

const { t } = useI18n();
const baseURL = useRuntimeConfig().app.baseURL;
const fallbackImageSrc = `${baseURL}images/no_poster.jpg`;
</script>

<template>
  <div
    class="absolute bottom-[66px] left-4 flex h-[72px] cursor-pointer overflow-hidden rounded bg-[rgba(0,_0,_0,_0.72)] max-md:max-w-xs md:w-[344px]"
  >
    <div class="flex w-2/5">
      <RImage
        class="relative object-fill"
        :src="props.video.poster"
        :fallback-src="fallbackImageSrc"
      />
    </div>
    <div class="text-truncate flex w-3/5 flex-col p-2">
      <div class="text-sm font-medium text-neutral-light-500">
        {{ t("labels.next") }}
      </div>
      <div class="line-clamp-2 text-sm font-medium text-white">
        {{ props.categoryName ? props.categoryName + " • " + props.video.title : props.video.title }}
      </div>
    </div>
  </div>
</template>
