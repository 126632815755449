<script setup lang="ts">
import type { DeepReadonly } from "vue";
import type {
  VideoJsPlayer as IPixellotPlayer,
  VideoJsPlayerOptions as IPixellotPlayerOptions,
  IPlayerControlLink,
  ITag as IPixellotTag,
  IAnalyticsOptions,
  TOptionalAnalyticsOptions,
  ITagGroup,
} from "@pixellot/web-sdk";
import { onBeforeUnmount, onMounted, ref, readonly, computed, shallowRef, watch } from "vue";
import { setupPlayer, version as playerVersion } from "@pixellot/web-sdk";
import type { IPlayerState } from "../util/state";
import type { IPixellotPlayerSource } from "../types";
import "@pixellot/web-sdk/style.css";
import { createPlayerState } from "../util/state";
import useSource from "../composables/use-source";

const props = defineProps<{
  panoControlsSelector?: string;
  analytics?: IPixellotPlayerOptions["analytics"];
  controlLink?: IPlayerControlLink;
  title?: string;
  tags?: IPixellotTag[];
  renderTagGroups?: boolean;
  src?: null | string | IPixellotPlayerSource | IPixellotPlayerSource[];
  trimmingToolShown?: boolean;
  getStartTimeOnSourceSwitch?:
    | null
    | ((params: {
      oldSrc: IPixellotPlayerSource;
      newSrc: IPixellotPlayerSource;
      playedTime: number;
    }) => number | Promise<number>);
  seekButtons?: {
    isActive: boolean;
    onClickNext?: () => void;
    onClickPrevious?: () => void;
    isPreviousDisabled: boolean;
    isNextDisabled: boolean;
  };
  // VideoJS options
  id?: string | undefined;
  aspectRatio?: string | undefined;
  autoplay?: IPixellotPlayerOptions["autoplay"] | undefined;
  defaultVolume?: number | undefined;
  fill?: boolean | undefined;
  fluid?: boolean | undefined;
  loop?: boolean | undefined;
  muted?: boolean | undefined;
  playsinline?: boolean | undefined;
  poster?: string | undefined;
  responsive?: boolean | undefined;
  switchType?: "button" | "selector";
}>();

const emit = defineEmits<{
  // (event: EventKey): void;
  (
    event: "mounted",
    data: {
      video: HTMLVideoElement;
      player: IPixellotPlayer;
      state: IPlayerState | null;
    },
  ): void;
  (event: "unmounted"): void;
  (event: "tagClick", data: { event: PointerEvent | MouseEvent; data: IPixellotTag | ITagGroup }): void;
}>();

const mounted = shallowRef(false);
const videoRef = ref<HTMLVideoElement | null>(null);
const player = shallowRef<IPixellotPlayer | null>(null);
const state = ref<IPlayerState | null>(null);
const { sources, changeSources, switchSourceToType, activeSource, setSrc } = useSource(player, state, {
  getStartTimeOnSourceSwitch: props.getStartTimeOnSourceSwitch,
});
const videoId = ref<string | null>(null);

const readOnlyState = computed<DeepReadonly<IPlayerState> | null>(() => (state.value ? readonly(state.value) : null));

onMounted(() => {
  if (!videoRef.value) {
    console.error("Player target element not found!");
    return;
  }

  // Create player
  const pixellotPlayer = setupPlayer(videoRef.value as HTMLVideoElement, {
    id: props.id,
    aspectRatio: props.aspectRatio || "16:9",
    autoplay: props.autoplay,
    defaultVolume: props.defaultVolume,
    fill: props.fill,
    fluid: props.fluid,
    loop: props.loop,
    muted: props.muted,
    playsinline: props.playsinline,
    poster: props.poster,
    responsive: props.responsive,
    // analytics: props.analytics,
    tags: {
      renderGroups: props.renderTagGroups,
      data: props.tags,
      onTagClick: (event, data) => emit("tagClick", { event, data }),
    },
    // title: props.title,
    // controlLink: props.controlLink,
    // controlLink: {
    //   label: '123'
    // },
  });

  if (props.panoControlsSelector) {
    pixellotPlayer.panoControls({ elSelector: props.panoControlsSelector });
  }

  // TODO: Think if this is necessary
  // Stringing video.js events to vue emits.
  // events.forEach((eventKey) => {
  //   pixellotPlayer._videoJSPlayer.on(eventKey, (payload) => emit(eventKey))
  // })

  // Create player state.
  createPlayerState(pixellotPlayer, {
    onInit(initState) {
      state.value = initState;
    },
    onUpdate(key, value) {
      if (state.value) {
        state.value[key] = value;
      }
    },
  });

  player.value = pixellotPlayer;
  videoId.value = pixellotPlayer.el().id;
  mounted.value = true;
  emit("mounted", {
    video: videoRef.value as HTMLVideoElement,
    player: player.value,
    state: readOnlyState.value,
  });

  if (props.src) {
    changeSources(props.src);
  }

  // TODO: Fix this workaround
  if (props.controlLink) pixellotPlayer.controlLink(props.controlLink);

  if (props.analytics) {
    setAnalytics(props.analytics);
  }

  if (props.seekButtons) {
    pixellotPlayer.seekButtons(props.seekButtons);
  }
});

function setAnalytics(options?: IPixellotPlayerOptions["analytics"]) {
  if (!player.value) return;

  if (player.value.analyticsAPI) {
    if (options) {
      player.value.analyticsAPI.updateOptions(options as TOptionalAnalyticsOptions);
    }
    else {
      player.value.analyticsAPI.clearOptions();
      player.value.analyticsAPI.destroy();
      player.value.analyticsAPI = null;
    }
  }
  else {
    const payload = options ? (Object.assign({ playerVersion }, options) as IAnalyticsOptions) : null;
    player.value.analytics(payload);
  }
}

function onPanoButtonClick() {
  switchSourceToType("pano");
}

function updateSwitcherElement(show: boolean) {
  if (props.switchType === "selector") {
    const items = [...sources.values()].map(src => ({ type: src.type, value: src.url, onClick: () => switchSourceToType(src.type) }));
    player.value?.sourceSelector({ items });
  }
  else {
    player.value?.panoBtn({ show, onClick: onPanoButtonClick });
  }
}

// Reactively update the player, when relevant prop is changed
watch(
  () => props.title,
  title => player.value?.title(title || ""),
);
watch(
  () => props.controlLink,
  link => player.value?.controlLink(link || null),
);
watch(
  () => props.tags || [],
  tags => player.value?.tagsAPI.set(tags || []),
);
watch(
  () => props.seekButtons,
  buttons => player.value?.seekButtons(buttons || null),
  { deep: true },
);
watch(
  () => props.src,
  (src, oldSrc) => {
    // Update current sources list only if the sources list changed
    // Otherwise it can lead to redundant player reloads
    if (JSON.stringify(oldSrc) !== JSON.stringify(src)) {
      changeSources(src);
    }
  },
);
watch(
  () => props.trimmingToolShown,
  show => player.value?.trimAPI.toggle(show),
);
watch(sources, (sourcesMap) => {
  const show = sourcesMap.size > 1;
  updateSwitcherElement(show);
});
watch(
  () => props.analytics,
  options => setAnalytics(options),
);

onBeforeUnmount(() => {
  if (player.value) {
    if (player.value?.adsAPI) {
      player.value?.adsAPI.resetAd();
    }

    player.value.dispose();
    player.value = null;
    emit("unmounted");
  }
});

defineExpose({
  video: videoRef,
  player,
  state: readOnlyState,
  source: activeSource,
  setSrc,
  setSrcType: switchSourceToType,
});
</script>

<template>
  <div class="pixellot-player">
    <video ref="videoRef" />
    <Teleport
      v-if="videoId"
      :to="`#${videoId}`"
    >
      <slot
        v-if="mounted"
        :video="videoRef!"
        :player="player!"
        :state="readOnlyState!"
        :source="activeSource"
        :set-src="setSrc"
        :set-src-type="switchSourceToType"
      />
    </Teleport>
  </div>
</template>

<style>
.pixellot-player {
  position: relative;
}
</style>
