<script setup lang="ts">
import { ref, computed } from "vue";
import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";
import type { IPlayerState } from "../util/state";

const props = defineProps<{
  player: IPixellotPlayer;
  playerState: IPlayerState;
  filename?: string;
  disableAutoSave?: boolean;
}>();

const emit = defineEmits<{
  (event: "recording:started" | "recording:stopped" | "recording:failed"): void;
}>();

const recordingInterval = ref();
const recordedSeconds = ref(0);
const hasError = ref(false);
const isRecording = ref(false);
const isDisabled = computed(() => {
  if (isRecording.value) return recordedSeconds.value <= 3;

  return hasError.value || props.playerState.muted || props.playerState.paused;
});
const text = computed(() => (isRecording.value ? "Stop" : "Record"));

function onRecording() {
  const player = props.player;
  const recorder = player.panoAPI.recorder();

  if (recorder && recorder.getRecordingState() === "recording") {
    recordedSeconds.value += 1;
    isRecording.value = true;
  }
  else {
    isRecording.value = false;
    clearInterval(recordingInterval.value);
  }
}

function onClick() {
  const player = props.player;
  const recorder = player.panoAPI.recorder();
  const onError = () => {
    hasError.value = true;
    isRecording.value = false;
    emit("recording:failed");
  };

  if (recorder) {
    if (isRecording.value) {
      finishRecording();
    }
    else {
      recordedSeconds.value = 0;
      recorder.startRecording({ onError });
      emit("recording:started");

      recordingInterval.value = setInterval(onRecording, 1000);

      player.one("pause", () => isRecording.value && finishRecording());
      player.one("ended", () => isRecording.value && finishRecording());
      player.one("seeked", () => isRecording.value && finishRecording());
      player.one("volumechange", () => isRecording.value && finishRecording());

      isRecording.value = true;
    }
  }
  else {
    isRecording.value = false;
  }
}

function finishRecording() {
  const player = props.player;
  const recorder = player.panoAPI.recorder();

  if (recorder) {
    recorder.stopRecording();
    isRecording.value = false;
    emit("recording:stopped");

    if (!props.disableAutoSave) recorder.saveRecording(props.filename);
  }
}
</script>

<template>
  <slot
    :is-disabled="isDisabled"
    :text="text"
    :is-recording="isRecording"
    :recorded-seconds="recordedSeconds"
    :on-click="onClick"
  />
</template>
