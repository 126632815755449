import type { VideoJsPlayer as Player } from "@pixellot/web-sdk";

// https://github.com/surmon-china/videojs-player/blob/main/player/state.ts
const StateConfig = {
  src: {
    getter: (player: Player) => player.src(),
  },
  volume: {
    events: ["volumechange"],
    getter: (player: Player) => player.volume(),
  },
  muted: {
    events: ["volumechange"],
    getter: (player: Player) => player.muted(),
  },
  poster: {
    events: ["posterchange"],
    getter: (player: Player) => player.poster(),
  },
  paused: {
    events: ["pause", "play", "playing"],
    getter: (player: Player) => player.paused(),
  },
  ended: {
    events: ["ended", "play"],
    getter: (player: Player) => player.ended(),
  },
  currentTime: {
    events: ["timeupdate"],
    getter: (player: Player) => player.currentTime(),
  },
  duration: {
    events: ["durationchange"],
    getter: (player: Player) => {
      if (player.readyState() === 0) return 0;
      const isLive = player.liveTracker?.isLive();
      return isLive ? player.liveTracker.seekableEnd() : player.duration();
    },
  },
  isLive: {
    getter: (player: Player) => player.liveTracker?.isLive(),
  },
  userActive: {
    events: ["useractive", "userinactive"],
    getter: (player: Player) => player.userActive(),
  },
  readyState: {
    events: ["loadeddata"],
    getter: (player: Player) => player.readyState(),
  },
  networkState: {
    events: ["loadeddata", "error"],
    getter: (player: Player) => player.networkState(),
  },
  error: {
    events: ["loadeddata", "error"],
    getter: (player: Player) => player.error(),
  },
  hasStarted: {
    events: ["loadstart", "play"],
    getter: (player: Player) => player.hasStarted(),
  },
  panoMode: {
    events: ["pano-mode-change"],
    getter: (player: Player) => player.panoAPI.mode(),
  },
  height: {
    events: ["playerresize"],
    getter: (player: Player) => player.currentHeight(),
  },
  width: {
    events: ["playerresize"],
    getter: (player: Player) => player.currentWidth(),
  },
};

type PlayerBastState = {
  [K in keyof typeof StateConfig]: ReturnType<
    (typeof StateConfig)[K]["getter"]
  >;
};
export interface IPlayerState extends PlayerBastState {
  playing: boolean;
  waiting: boolean;
}

export interface CreatePlayerStateOptions {
  onInit: (state: IPlayerState) => void;
  onUpdate: <K extends keyof IPlayerState>(
    key: K,
    value: IPlayerState[K],
    state: IPlayerState
  ) => void;
}

export const createPlayerState = (
  player: Player,
  options: CreatePlayerStateOptions,
) => {
  const keys = Object.keys(StateConfig) as Array<keyof typeof StateConfig>;
  const state = keys.reduce(
    (result, key) => ({ ...result, [key]: StateConfig[key].getter(player) }),
    { playing: false, waiting: false } as IPlayerState,
  );

  // update state
  const updateState = (key: keyof IPlayerState, value: any) => {
    state[key] = value as never;
    options.onUpdate(key, value, { ...state });
  };

  // playing state https://github.com/videojs/video.js/issues/181
  // un-loop: play > playing > pause > ended
  // loop: play > playing > [ended > restart] waiting > playing
  player.on(["pause", "ended"], () => {
    updateState("playing", false);
  });
  player.on(["play", "playing"], () => {
    updateState("playing", true);
  });

  // https://github.com/videojs/video.js/blob/75ea699273d659593d95429d185865fb6e49cb93/src/js/player.js#L1743
  player.on("waiting", () => {
    updateState("waiting", true);
    const timeWhenWaiting = player.currentTime();
    const timeUpdateListener = () => {
      if (timeWhenWaiting !== player.currentTime()) {
        updateState("waiting", false);
        player.off("timeupdate", timeUpdateListener);
      }
    };
    player.on("timeupdate", timeUpdateListener);
  });

  keys.forEach((key) => {
    const target = StateConfig[key];
    const baseEvents = ["loadstart", "loadedmetadata"];
    player.on(baseEvents.concat((target as any).events ?? []), () => {
      updateState(key, target.getter(player));
    });
  });

  // init state
  options.onInit({ ...state });
};
