export function fetchContentTypeHeaderFromURL(url: string) {
  return fetch(url, { method: "HEAD" }).then((response) => {
    const contentType = response.headers.get("content-type");

    if (!contentType) {
      console.warn(
        "Failed to fetch content-type header from the provided URL!",
      );
      return null;
    }

    return contentType;
  });
}

export function urlHasExtension(url: string) {
  const knownExtensions = [".mp4", ".m3u8", ".mov"];

  return url.includes(".") && knownExtensions.some(ext => url.endsWith(ext));
}
